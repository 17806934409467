import React from 'react'
import { Link,navigate } from 'gatsby';
import { gsap } from 'gsap'

const FadeOutLink = ({to, ...rest}) => {
  
  let isCurrentPage = false;

  const currentPage = ({ isCurrent }) => {
    // setIsCurrentPage(isCurrent)
    isCurrentPage = isCurrent
    // return isCurrent
  }

  const clickHanlder = (e) => {
    e.preventDefault()
    if(isCurrentPage) return;
    const tl = gsap.timeline({
      onComplete: () => {
        navigate(to)
      }
    });
    tl.to("body", {opacity:0, duration: .5});
    // tl.to("body", {opacity:1, duration: 1});
  }

  return (
    <Link to={to} getProps={currentPage} onClick={clickHanlder} {...rest} />
  )
}

export default FadeOutLink