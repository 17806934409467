import React from 'react'

const HoverEffectArrow = ({ direction="right",primary="#FFFFFF",secondary="#03488b",tertiary=null,className,...rest}) => {

  let directionClass = "";
  if(direction === "left"){
    directionClass = "transform rotate-180 origin-center";
  }
  if(direction === "up"){
    directionClass = "transform -rotate-90 origin-center";
  }
  if(direction === "down"){
    directionClass = "transform rotate-90 origin-center";
  }


 return (

     <svg xmlns="http://www.w3.org/2000/svg" overflow="visible" width="41" height="41" viewBox="0 0 41 41" className={`relative group ${className} ${directionClass}`} {...rest}>
       <g className="transform origin-center"  fill="none" fillRule="evenodd">
         <g>
           <g>
             <path stroke={primary} d="M.5 20.5c0-11.046 8.954-20 20-20s20 8.954 20 20-8.954 20-20 20-20-8.954-20-20zM32.423 20.5L8.308 20.5" transform="translate(-310 -2220) translate(310 2220)"/>
             <path stroke={primary} d="M23.927 12.004L32.423 20.5 23.927 28.995" transform="translate(-310 -2220) translate(310 2220)"/>
           </g>
         </g>
       </g>
       <g className="scale-0 group-hover:scale-110 transform origin-center sp-transition duration-300"  fill="none" fillRule="evenodd">
         <g>
           <g>
             <path stroke={tertiary ?tertiary : primary} fill={tertiary ? tertiary : primary} d="M.5 20.5c0-11.046 8.954-20 20-20s20 8.954 20 20-8.954 20-20 20-20-8.954-20-20z" transform="translate(-310 -2220) translate(310 2220)"/>
             <path className="opacity-0 scale-50 group-hover:opacity-100 group-hover:scale-100 transition-all transform origin-center duration-200 delay-100" stroke={tertiary ? primary : secondary} d="M32.423 20.5L8.308 20.5 M23.927 12.004L32.423 20.5 23.927 28.995" transform="translate(-310 -2220) translate(310 2220)"/>
           </g>
         </g>
       </g>
     </svg>

 )

}

export default HoverEffectArrow