import React, { useEffect } from 'react'
import { Link,navigate } from 'gatsby';
import gsap from "gsap";
import get from 'lodash/get'


export const getLocoScrollY = () => {
  if(typeof window === "undefined") return false;
  if(get(window,"myScroll.scroll.instance.scroll.y") !== undefined){
    return get(window,"myScroll.scroll.instance.scroll.y")
  }else{
    return false;
  }
}

export const FadeEffectLink = ({children,to,fadeTarget,location,scrollTo=null,offset=null}) => {

  const pageFade = get(location,"state.fade")
  const pageLocoScrollTo = get(location,"state.locoScrollTo")


  const LeaveEffect = (path,options) => {

    if(!fadeTarget.current) return;
    const tl = gsap.timeline({
      onComplete: () => {
        navigate(path,options)
      }
    });
    tl.to(fadeTarget.current, {y: 50,opacity:0, duration: .5});
  }

  const EnterEffect = () => {
    const tl = gsap.timeline({
      onComplete: () => {

      }
    });
    // tl.to(plRef.current, {y: 50,opacity:0, duration: .5});
    // tl.fromTo(fadeTarget.current,{y: 50,opacity:0}, {y: 0,opacity:1, duration: 1});
    const TitleCategory = document.getElementById("TitleCategory")
    const TitleCategoryText = TitleCategory.innerText
    const ProductsBlocks = document.querySelectorAll(".product-block")
    ShuffleText({
      el:TitleCategory,
      text:TitleCategoryText,
    })
    ProductsBlocks.forEach((block,idx)=>{

      tl.fromTo(block,{y:70,opacity:0},{y:0,opacity:1,duration:2,ease: "power4.inOut"},idx * .3)
      const Texts = block.querySelectorAll("p")
      Texts.forEach((txt,_ind)=>{
        ShuffleText({
          el:txt,
          text:txt.innerText ,
          rounds:50,
          gap:50,
        })
      })
    })
  }

  useEffect(()=>{
    if(typeof window === "undefined" || !fadeTarget || !pageFade) return;
    if(pageLocoScrollTo && window.myScroll){
      if(scrollTo && scrollTo.current){
        window.myScroll.scrollTo(scrollTo.current,{
          offset : offset ? offset : 0
        })
      }else{
        window.myScroll.setScroll(0,pageLocoScrollTo)
        window.myScroll.update()
      }
    }

    EnterEffect()
  })


  return(
    <Link
      to={to}
      onClick={(e)=>{
        e.preventDefault()
        const locoScrollTo = getLocoScrollY()
        LeaveEffect(
          to,
          {
            state: {
              scroll: false,
              fade:true,
              locoScrollTo: locoScrollTo
            },
          }
        )
      }}
    >
      {children}
    </Link>
  )
}

export const BrandSwitchLink = ({children,location,to}) => {

  const pageLocoScrollTo = get(location,"state.locoScrollTo")
  const pageSwitchBrand = get(location,"state.switchBrand")

  let isCurrentPage = false;
  const currentPage = ({ isCurrent }) => {
    // setIsCurrentPage(isCurrent)
    isCurrentPage = isCurrent
    // return isCurrent
  }

  const LeaveEffect = (path,options)=>{
    if(isCurrentPage) return
    const Switcher = document.getElementById("Switcher")
    const PLcontainer = document.getElementById("PLcontainer")
    if(!Switcher || !PLcontainer){
      navigate(path,options)
      // return navigate(path,options)
    }else{
      const tl = gsap.timeline({
        onComplete: () => {
          navigate(path,options)
        }
      });
      tl.to(PLcontainer,{opacity:.7,scale:.95,duration:.3,ease: "power2.out"})
      tl.to(PLcontainer,{x:PLcontainer.clientWidth +20,duration:.5,ease: "power2.out"})
      tl.to(Switcher, {scale:.95, duration: .3,transformOrigin:"0% 0%",ease: "power2.out"},0)
      tl.to(Switcher, {x: -Switcher.clientWidth,opacity:0, duration: .5,ease: "power2.out"},.3)
    }

  }

  const EnterEffect = () => {
    const Switcher = document.getElementById("Switcher")
    const PLcontainer = document.getElementById("PLcontainer")
    const ProductsBlocks = document.querySelectorAll(".product-block")
    const TitleBrand = document.getElementById("TitleBrand")
    const TitleBrandText = TitleBrand.innerText
    const TitleCategory = document.getElementById("TitleCategory")
    const TitleCategoryText = TitleCategory.innerText

    const CategoryList = PLcontainer.querySelectorAll("li")

    ShuffleText({
      el:TitleBrand,
      text:TitleBrandText,
    })
    ShuffleText({
      el:TitleCategory,
      text:TitleCategoryText,
    })

    CategoryList.forEach((category,idx)=>{
      const categoryText = category.innerText
      ShuffleText({
        el:category,
        text:categoryText ,
      })
    })
    const tl = gsap.timeline();
    tl.fromTo(Switcher,{x: -Switcher.clientWidth,opacity:0},{x:0,opacity:1,duration:1,ease: "power2.out"})

    ProductsBlocks.forEach((block,idx)=>{

      tl.fromTo(block,{y:70,opacity:0},{y:0,opacity:1,duration:2,ease: "power4.inOut"},idx * .3)
      const Texts = block.querySelectorAll("p")
      Texts.forEach((txt,_ind)=>{
        ShuffleText({
          el:txt,
          text:txt.innerText ,
          rounds:50,
          gap:50,
        })
      })
    })
  }

  useEffect(()=>{

    if(typeof window === "undefined" || !pageSwitchBrand) return;

    if(pageLocoScrollTo && window.locoScroll){
      window.locoScroll.setScroll(0,pageLocoScrollTo)
      window.locoScroll.update()
    }

    EnterEffect()
  })

  return(
    <Link
      getProps={currentPage}
      to={to}
      onClick={(e)=>{
        e.preventDefault()
        const Switcher = document.getElementById("Switcher")
        const locoScrollTo = Switcher ? getLocoScrollY() : 0
        LeaveEffect(
          to,
          {
            state: {
              scroll: false,
              switchBrand:true,
              locoScrollTo: locoScrollTo
            },
          }
        )
      }}
    >
      {children}
    </Link>
  )
}



const ShuffleText = ({el,text,gap=30,rounds = 30,CaseSensitive=true}) => {
  const RandomSource = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  // text = text.toUpperCase()
  const RandomSourceSplit = RandomSource.split('')

  const SplitText = text.split("")
  const RandomPick = (char) => {
    if( char === " " )return " "
    if(CaseSensitive){
      if(char.toUpperCase() === char){
      //  大寫
        return RandomSourceSplit[Math.floor(Math.random() * RandomSourceSplit.length)].toUpperCase()
      }else{
      //  小寫
        return RandomSourceSplit[Math.floor(Math.random() * RandomSourceSplit.length)].toLowerCase()
      }
    }
    return RandomSourceSplit[Math.floor(Math.random() * RandomSourceSplit.length)];
  }

  const RenderFinal = () =>{
    // const El = textRef.current
    let _gap = 1
    let intervalGap = setInterval(()=>{
      if(_gap >= SplitText.length){
        clearInterval(intervalGap)
      }
      let render = "";
      let after = "";
      let before = text.slice(0,_gap)
      let rest = text.slice(_gap)
      for(let char of rest){
        after += RandomPick(char)
      }
      render = before + after
      // El.innerText = render
      el.innerText = render
      _gap ++
    },30)

  }

  const RandomHanlder = () => {
    // const El = textRef.current
    let round = 0;
    let interval = setInterval(
      ()=>{

        if(round >= rounds){
          clearInterval(interval)
          RenderFinal()
        }

        let render = "";
        for(let char of SplitText){
          render += RandomPick(char)
        }
        // El.innerText = render
        el.innerText = render
        round ++
      },30
    )
  }

  RandomHanlder()
}