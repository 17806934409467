import React, { useRef, useState, useEffect, useContext } from "react";
// import { Link } from 'gatsby'
import { BrandSwitchLink } from "../templates/FadeEffectLink";
import Link from "./fade-out-link";
import BugerIcon from "../components/buger-icon";
import Logo from "../assets/logo_convert2.svg";
import styled from "styled-components";
import GlobalIcon from "../assets/global.svg";
import SearchIcon from "../assets/search.svg";
import tw from "tailwind-styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import ContentfulAssets from "../hooks/contentful-assets-hook";
import BrandCategory from "../hooks/brand-category-hook";
import siteContext from "../context/site-context";
import SearchForm from "./search-form";
import { useLocation } from "@reach/router";

const SvgLogoStyle = styled.div`
  width: 100%;
  height: auto;
  path {
    transition: all 0.5s 0 ease-in-out;
  }
  react {
    transition: all 0.5s 0 ease-in-out;
  }
`;

const StyledLink = tw.button`
  block
  relative
  z-10
  transition
  duration-200
  text-menu-gray 
  text-3xl 
  border-b
  border-modal-gray
  mb-4
  pb-2
  px-2
  hover:text-theme-blue
  hover:border-theme-blue
  last:mb-0
  md:mb-0
  md:text-4xl
  md:px-3
  lg:text-5xl
  xl:text-6xl
`;

const MouseMoivingImg = ({ left, top, opacity = 0 }) => {
  return (
    <div
      className="hidden z-20 absolute w-10 h-10 flex justify-center items-center text-sm text-theme-blue border-theme-blue border rounded-full -mt-5 -ml-5 pointer-events-none transform transition-all duration-100 lg:flex"
      style={{
        left,
        top,
        opacity,
      }}
    >
      VIEW
    </div>
  );
};

const CategoryUnit = ({
  slug,
  title,
  gatsbyImage,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <BrandSwitchLink to={`/category/${slug}`}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className="relative text-2xl group mr-10 inline-block"
      >
        <div className="absolute z-0 pointer-events-none w-full h-full top-1/2 left-1/2 opacity-0 duration-300 group-hover:opacity-100">
          <div
            className="w-56 h-56 rounded-full bg-gray-400 overflow-hidden"
            style={{
              transform: "translateX(-50%) translateY(-50%)",
            }}
          >
            <GatsbyImage
              image={gatsbyImage}
              alt={title}
              className="w-full h-full"
            />
          </div>
        </div>
        <div className="relative z-10 text-gray-500 group-hover:text-white">
          {title}
        </div>
      </div>
    </BrandSwitchLink>
  );
};

const CurrentRenderer = ({ brand, category }) => {
  const CateStringArray = category.split(" ");
  const CateStringArrayLength = CateStringArray.map((cate, idx) => {
    return cate.length;
  });
  const MaxLength = Math.max(...CateStringArrayLength);

  let fontSizeClass = "text-7xl lg:text-8xl 2xl:text-9xl";

  if (MaxLength > 7) {
    fontSizeClass = "text-6xl-plus lg:text-7xl 2xl:text-8xl";
  }

  if (MaxLength > 10) {
    fontSizeClass = "text-5xl lg:text-6xl-plus 2xl:text-7xl";
  }

  return (
    <div className="text-theme-blue relative z-10">
      <h4 className="italic mb-3 text-3xl pl-3 xl:text-4xl 2xl:text-5xl uppercase">
        {brand} /{" "}
      </h4>
      <h3 className={`font-bold w-0 italic ${fontSizeClass}`}>{category}</h3>
    </div>
  );
};

const MenuModal = ({ active = false, defaultCurrent = null, setActive }) => {
  const { current: currentPage, resultPage } = useContext(siteContext);
  const [searchFormOpen, setSearchFormOpen] = useState(false);
  const brands = BrandCategory().brands.nodes;
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [mouseOpacity, setMouseOpacity] = useState(0);
  const [current, setCurrent] = useState(defaultCurrent);
  const [showCategory, setShowCategory] = useState(false);

  const mouseMovingHandler = (e) => {
    setMouseX(e.clientX);
    setMouseY(e.clientY);
  };

  return (
    <>
      <div
        data-scroll-sticky
        data-scroll-target="#locomotive-wrap"
        className={`bg-white w-full absolute z-20 ${active ? "" : "hidden"}`}
        style={{ height: "200vh" }}
      />
      <div
        onMouseMove={mouseMovingHandler}
        data-scroll-sticky
        data-scroll-target="#locomotive-wrap"
        className={`z-30 pt-10 fixed overflow-y-auto w-screen h-screen transition-opacity duration-500 bg-white flex flex-col ${
          active ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <MouseMoivingImg left={mouseX} top={mouseY} opacity={mouseOpacity} />
        <div className="flex-grow flex items-center">
          <div className="border-l-4 border-theme-blue w-full px-11 py-1 md:border-l-8 md:py-5 md:grid md:grid-cols-5 md:pr-20 xl:pr-32 2xl:pr-40">
            <div className="hidden md:col-span-2 md:flex md:items-end">
              {current ? (
                <CurrentRenderer
                  brand={current.brand}
                  category={current.category}
                />
              ) : null}
            </div>
            <div className="md:col-span-3 md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-5 lg:gap-x-16 lg:gap-y-10 xl:gap-x-24">
              <StyledLink
                className={`${
                  currentPage === "about" ? "text-theme-blue" : null
                }`}
              >
                <Link to={"/about"}>ABOUT US</Link>
              </StyledLink>
              <StyledLink>
                <Link to={"/news"}>NEWS</Link>
              </StyledLink>
              <StyledLink
                className="lg:mt-8 md:hidden"
                onClick={() => {
                  setShowCategory(!showCategory);
                }}
              >
                PRODUCTS
              </StyledLink>
              <div
                className={`md:hidden ${
                  showCategory ? "max-h-screen" : "max-h-0"
                } overflow-hidden transition-all duration-500`}
              >
                <div className="pt-2 pb-6">
                  {brands.map((brand, idx) => {
                    const { slug, title, category } = brand;
                    const sortedCategories = category.sort(function (a, b) {
                      return a.order - b.order;
                    });
                    return (
                      <div
                        key={`brand-mobile-${slug}-${idx}`}
                        className="flex flex-wrap mb-2"
                      >
                        <div className="relative z-10 w-full">
                          <Link to={`/${slug}`}>
                            <h4 className="text-xl text-theme-blue font-semibold italic mb-1">
                              {title}
                            </h4>
                          </Link>
                        </div>
                        <div className="flex-grow">
                          {sortedCategories.map((cate, idx) => {
                            const { slug, title: cateTitle } = cate;
                            return (
                              <div
                                key={`moble-cate-list-${idx}`}
                                className="text-category-gray text-sm inline-block mr-4"
                              >
                                <Link to={`/category/${slug}`}>
                                  {cateTitle}
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <StyledLink className="lg:mt-8 hidden md:block">
                <Link to={`/${brands[0].slug}`}>PRODUCTS</Link>
              </StyledLink>
              <StyledLink className="lg:mt-8">
                <Link to={"/contact"}>CONTACT</Link>
              </StyledLink>
              <div className="hidden md:block md:col-span-2">
                {brands.map((brand, idx) => {
                  const { slug, title, category } = brand;
                  const sortedCategories = category.sort(function (a, b) {
                    return a.order - b.order;
                  });
                  return (
                    <div
                      key={`brand-${slug}-${idx}`}
                      className="flex flex-wrap mb-2"
                    >
                      <div className="relative z-10 w-full">
                        <Link to={`/${slug}`}>
                          <h4 className="text-2xl text-theme-blue font-semibold italic mb-1">
                            {title}
                          </h4>
                        </Link>
                      </div>
                      <div className="flex-grow">
                        {sortedCategories.map((cate, idx) => {
                          const {
                            slug,
                            title: cateTitle,
                            cover: { gatsbyImage },
                          } = cate;
                          return (
                            <CategoryUnit
                              key={`catelink-${slug}-${idx}`}
                              slug={slug}
                              title={cateTitle}
                              gatsbyImage={gatsbyImage}
                              onMouseEnter={() => {
                                setMouseOpacity(1);
                                setCurrent({
                                  brand: title,
                                  category: cateTitle,
                                });
                              }}
                              onMouseLeave={() => {
                                setMouseOpacity(0);
                                setCurrent(defaultCurrent);
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-menu-footer pb-10 px-11">
          <div className="md:hidden">
            <div className="mb-6">
              <GlobalIcon className="w-6 h-6 inline-block align-middle" />
              <div className="text-theme-blue font-bold inline-block ml-2 align-middle leading-none">
                EN
              </div>
            </div>
            <div className="flex flex-wrap w-full">
              <SearchIcon
                className="w-6 h-6 inline-block align-middle"
                onClick={() => {
                  setSearchFormOpen(!searchFormOpen);
                }}
              />
              <div
                className={`transition-all duration-300 pl-3 w-0 overflow-hidden ${
                  searchFormOpen ? "flex-grow" : null
                }`}
              >
                <SearchForm
                  resultPage={resultPage}
                  className="border-theme-blue border-b px-1 text-theme-blue outline-none bg-transparent flex-grow"
                  submitCallback={() => {
                    setActive(false);
                  }}
                  showSubmitButton
                />
              </div>
            </div>
          </div>
          <div className="hidden md:block text-xs text-gray-500">
            ©PRO GYM DESIGNS CO., LTD All Rights Reserved. Site by{" "}
            <a
              className="underline"
              href="https://mounts-studio.com/"
              target="_blank"
            >
              Mounts Studio
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default () => {
  const {
    allAssets: { earthIcon, searchIcon },
  } = ContentfulAssets();
  const { current: currentPage, resultPage, navstyle  } = useContext(siteContext);
  const [searchFormOpen, setSearchFormOpen] = useState(false);
  const navRef = useRef(null);
  const [fixed, setFixed] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [hideNav, setHideNav] = useState(false);
  const [active, setActive] = useState(false);
  const effectSet = useRef(false);
  const location = useLocation();
  const scrollHanlder = () => {};

  console.log(navstyle)

  useEffect(() => {
    // 在此處處理路由變化的操作，例如更新網站標題、設定活動連結等等
    setActive(false)
  }, [location]);

  useEffect(() => {
    if (!navRef.current) return;
    console.log(navstyle)
    const navHeight = navRef.current.offsetHeight;
    if (window.myScroll && !effectSet.current) {
      window.myScroll.on("scroll", (args) => {
        console.log(args);
        const {direction} = args
        const y = args.scroll.y
        if (y > navHeight) {
          if (direction === 'up') {
            setHideNav(false);
          } else {
            setHideNav(true);
          }
        } else {
          setHideNav(false);
          setFixed(true)
        }
      });
      effectSet.current = true;
    }
  });

  return (
    <>
      <nav
        role="navigation"
        ref={navRef}
        className={`${
          hideNav && !active ? "-top-28" : "top-0"
        } w-screen bg-transparent duration-500 z-40 flex item-center justify-between px-6 md:px-20 fixed will-change-scroll ${
          fixed ? "py-4 md:py-6" : "py-6 md:py-10"
        }`}
        style={{
          // top: hideNav && !active ? `-105px` : '0px',
          transitionProperty: "top",
        }}
        data-scroll-sticky
        data-scroll-target="#locomotive-wrap"
      >
        <div>
          <Link to="/">
            <SvgLogoStyle>
              <Logo
                className={`w-28 h-auto transition-all duration-500 bg-transparent ${
                  active || navstyle === "dark"
                    ? "fill-theme-blue"
                    : "fill-white"
                } ${fixed ? "md:w-24" : "md:w-32 "}`}
              />
            </SvgLogoStyle>
          </Link>
        </div>
        <div className="flex flex-wrap items-center">
          <div className="mr-5 flex-wrap items-center hidden md:flex">
            <div className="mr-5">
              <div className="flex">
                <div
                  className={`w-7 h-7 cursor-pointer ${
                    active || navstyle === "dark" ? "stroke-theme-blue-svg" : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: searchIcon.svg.content }}
                  onClick={() => {
                    setSearchFormOpen(!searchFormOpen);
                  }}
                />
                <div
                  className={`transition-all duration-500 overflow-hidden ${
                    searchFormOpen ? "w-56 pl-3" : "w-0"
                  }`}
                >
                  <SearchForm
                    onBlur={() => {
                      setSearchFormOpen(false);
                    }}
                    submitCallback={() => {
                      setActive(false);
                    }}
                    resultPage={resultPage}
                    className={`transition-all duration-500 border-b px-1 outline-none w-full bg-transparent ${
                      active || navstyle === "dark"
                        ? "border-theme-blue text-theme-blue"
                        : "border-white text-white"
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-center">
              <div
                className={`w-7 h-7 globe-icon mr-2 will-change-scroll ${
                  active || navstyle === "dark" ? "globe-icon-blue" : ""
                }`}
                dangerouslySetInnerHTML={{ __html: earthIcon.svg.content }}
              />
              <div
                className={`transition duration-500 font-bold flex-grow will-change-scroll ${
                  active || navstyle === "dark"
                    ? "text-theme-blue"
                    : "text-white"
                }`}
              >
                EN
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setActive(!active);
            }}
          >
            <BugerIcon active={active} navstyle={navstyle} />
          </div>
        </div>
      </nav>
      <MenuModal active={active} setActive={setActive} />
    </>
  );
};
