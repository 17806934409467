import React,{useState} from 'react'
import { navigate } from "gatsby"

const DefaultSubmitBtn = () => (<button type="submit" className="w-16 flex justify-center py-1 bg-theme-blue text-white rounded" >Search</button>)

const SearchForm = ({submitCallback=()=>{},children,SubmitButton,showSubmitButton,buttonClass="",show,className,resultPage="./",...rest}) =>{

  const [keyword,setKeyword] = useState("")
  SubmitButton = SubmitButton || DefaultSubmitBtn

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        navigate(resultPage + encodeURIComponent(keyword),
          {
            state: {
              keyword : keyword,
              replace: true
            },
          }
        )
        submitCallback()
      }}
      className={`${showSubmitButton ? 'flex w-full' : null}`}
    >
      <input
        onChange={(el)=>{
          setKeyword( el.target.value )
        }}
        className={className}
        inputMode="search"
        type="search"
        required
        {...rest}
      />
      {
        showSubmitButton ?
          <SubmitButton/>
          :
          null
      }
    </form>
  )
}

export default SearchForm