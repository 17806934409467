import React, { useContext, useState, useEffect } from "react";
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import siteContext from "../context/site-context";
import { useResizeDetector } from "react-resize-detector";

const Template = ({ children, locoscroll = false, current }) => {
  const siteContextData = useContext(siteContext);
  const resultPage = siteContextData.resultPage;
  const { width, height, ref } = useResizeDetector();
  const [ navstyle, setNavstyle] = useState(siteContextData.navstyle)

  useEffect(() => {
    if (
      !locoscroll &&
      typeof window !== "undefined" &&
      typeof window.locoScroll !== "undefined" &&
      window.locoScroll !== null
    ) {
      window.locoScroll.destroy();
      document.getElementById("___gatsby").removeAttribute("style");
      if (
        document.querySelector("html").hasAttribute("data-scroll-direction") &&
        document.querySelector("html").hasAttribute("data-direction")
      ) {
        document.querySelector("html").removeAttribute("data-scroll-direction");
        document.querySelector("html").removeAttribute("data-direction");
      }
    }
  });

  useEffect(() => {
    if (typeof window.myScroll !== "undefined" && window.myScroll !== null) {
      window.myScroll.update();
    }
  }, [height, width]);

  return (
    <>
      <div ref={ref} id="wrap" className="wrap">
        <siteContext.Provider value={{ current, navstyle, resultPage, setNavstyle }}>
          <Navigation />
          <div id="locomotive-wrap">
            <div className="relative md:z-10 w-full overflow-x-hidden">
              {children}
            </div>
            <Footer />
          </div>
        </siteContext.Provider>
      </div>
    </>
  );
};

export default Template;
