import React from "react"

const defaultState = {
  current:'index',
  resultPage:"/search/",
  navstyle: "normal",
}

const SiteContext = React.createContext(defaultState)


export default SiteContext

