import { useStaticQuery, graphql } from "gatsby";

const ContentfulAssets = () => {
  const data = useStaticQuery(graphql`
    {
      allAssets: contentfulRiquiredAssets {
        earthIcon {
          svg {
            content
          }
        }
        searchIcon {
          svg {
            content
          }
        }
        news: topsectionNews {
          gatsbyImage(
            height: 630
            width: 1200
            formats: WEBP
            placeholder: BLURRED
            quality: 50
          )
        }
        contact: topsectionContact {
          gatsbyImage(
            height: 630
            width: 1200
            formats: WEBP
            placeholder: BLURRED
            quality: 50
          )
        }
      }
    }
  `);
  return data;
};

export default ContentfulAssets;
