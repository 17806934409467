import React from 'react'
import styled from 'styled-components';

const BugerIconStyle = styled.section`
`;

const BugerIcon = ({active=false,navstyle="normal"}) => {

  const darkNav = active || navstyle === 'dark';

  return (
    <BugerIconStyle>
      <div className="w-8 h-2 relative transition-all ease-in-out duration-500 cursor-pointer rotate-0">
        <span
          className={`w-full border border-white absolute transition-all duration-300 ease-in-out rotate-0 left-0 top-0 transform ${darkNav ? '!border-theme-blue' : null} ${active ? "-rotate-45 top-2 opacity-0" : 'rotate-0'}`}
        />
        <span className={`w-full border border-white absolute transition-all duration-300 ease-in-out left-0 top-2 transform ${darkNav ? '!border-theme-blue' : null} ${active ? "rotate-45" : 'rotate-0'}`} />
        <span className={`w-full border border-white absolute transition-all duration-300 ease-in-out left-0 top-2 transform ${darkNav ? '!border-theme-blue' : null} ${active ? "-rotate-45": 'rotate-0'}`} />
      </div>
    </BugerIconStyle>
  )
}

export default BugerIcon