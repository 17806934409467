import { useStaticQuery, graphql } from "gatsby"

const SiteMetaHook = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          contact{
            tel
            fax
            email
            add
          }
        }
      }
    }
  `)
  return data
}

export default SiteMetaHook