import "./src/styles/global.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "locomotive-scroll/dist/locomotive-scroll.min.css";
import LocomotiveScroll from "locomotive-scroll";
import get from "lodash/get";
import { gsap } from "gsap";

import React from "react";
import Layout from "./src/layout"

export const wrapPageElement = ({ element, props }) => {
  const siteContextData = {
    current: props.pageContext.slug,
    navstyle: props.pageContext.navStyle,
    resultPage: props.pageContext.resultPage,
  };

  return (
    <Layout {...props} siteContextData={siteContextData}>
      {element}
    </Layout>
  );
};


let myScroll;
let winHeight;



export const shouldUpdateScroll = ({ routerProps }) => {
  return true;
};

export const onInitialClientRender = () => {
  const smooth = window.innerWidth > 990;
  const el = document.getElementById("locomotive-wrap");
  myScroll = new LocomotiveScroll({
    el,
    smooth,
    smoothMobile: false,
    getDirection: true,
    touchMultiplier: 2.5,
    lerp: 0.1,
    multiplier: 1.2,
  });
  const body = document.querySelector("body");
  window.myScroll = myScroll;

  const observer = new MutationObserver(() => {
    // 更新页面高度
    const height = body.offsetHeight;
    if (winHeight !== height) {
      myScroll && myScroll.update();
      winHeight = height;
    }
  });
observer.observe(body, { childList: true, subtree: true });

  myScroll.on('call', (func, stage, event) => {
    if (func ==='footer_event') {
        const footerContent = document.getElementById('footerContent')
        if (stage === 'enter') {
            footerContent.style.opacity = 1
            footerContent.style.transform = 'translateY(0)'
        } else {
            footerContent.style.opacity = .3
            footerContent.style.transform = 'translateY(90px)'
        }
    }
 });

};

export const onPreRouteUpdate = () => {};
export const onRouteUpdate = ({ location, prevLocation }) => {
  // alert("update")
    
  if (prevLocation && location.pathname === prevLocation.pathname) return;
  const scroll = get(location, "state.scroll");
  if (scroll !== false && myScroll) {
    // myScroll.scrollTo("top", { duration: 0 });
    myScroll.setScroll(0,0)
    myScroll.update()
  }

  if (scroll !== false) { 
    gsap.fromTo("body", { opacity: 0 }, { opacity: 1, duration: 1 });
  }
};
