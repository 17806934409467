import { useStaticQuery, graphql } from "gatsby"

const BrandCategory = () => {
  const data = useStaticQuery(graphql`
    {
      brands: allContentfulBrand {
        nodes {
          category {
            slug
            cover {
              gatsbyImage(
                quality: 50
                width: 1500
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
            title
            order
          }
          title
          slug
          order
        }
      }
    }
  `)
  return data
}

export default BrandCategory