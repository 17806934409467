import React, { useEffect, useRef } from "react";
import Link from "./fade-out-link";
import tw from "tailwind-styled-components";
import ArrowUp from "../assets/arrow-up.svg";
import SiteMetaHook from "../hooks/site-meta-hook";
import BrandCategory from "../hooks/brand-category-hook";
import HoverEffectArrow from "./hover-effect-arrow";


const StyledLink = tw.div`
text-4xl
uppercase
`;

const Footer = () => {
  const siteMeta = SiteMetaHook();
  const { tel, fax, email, add } = siteMeta.site.siteMetadata.contact;
  const brands = BrandCategory().brands.nodes.sort((a, b) => {
    return a.order - b.order;
  });
  const GotoTop = () => {
    if (window.myScroll) {
      window.myScroll.scrollTo("top");
    } else {
      // gsap.to(window, { scrollTo: "#wrap", duration: 1 });
    }
  };


  return (
    <footer
      id="footer"
      className="md:w-full md:z-0 md:bottom-0 md:left-0 bg-theme-blue overflow-hidden relative"
      data-scroll
      data-scroll-repeat
      data-scroll-call="footer_event"
    >
      <div
        className="hidden z-20 cursor-pointer absolute right-16 top-40 w-20 h-20 md:block"
        onClick={() => {
          GotoTop();
        }}
      >
        <HoverEffectArrow
          strokeWidth=".7"
          className="w-full h-full"
          direction="up"
        />
      </div>
      <div
        id="footerContent"
        className="px-12 h-screen pb-9 text-white flex flex-col relative transition-all opacity-300 duration-[1500ms] will-change-scroll"
      >
        <div className="flex-grow flex items-center md:container md:grid md:grid-cols-3 md:gap-7 xl:grid-cols-2">
          <div className="w-full relative sm:grid sm:grid-cols-2 sm:gap-6 sm:mx-auto md:mx-0 md:gap-7 md:col-span-2 xl:col-span-1 lg:gap-10">
            <div
              className="absolute cursor-pointer -right-5 bottom-0 w-10 h-10 md:hidden z-20"
              onClick={() => {
                GotoTop();
              }}
            >
              <ArrowUp className="w-full h-full" />
            </div>
            <h3 className="italic font-medium uppercase sm:col-span-2 text-3xl sm:text-5xl lg:text-6xl">
              Contact
            </h3>
            <div className="mt-5 text-sm sm:text-lg">
              <h4 className="font-medium italic">ADD.</h4>
              <p className="font-medium mt-2.5 italic leading-relaxed whitespace-pre-line">
                {add}
              </p>
            </div>
            <div className="mt-10 font-medium text-sm italic sm:text-lg">
              <p className="leading-relaxed">
                <span className="w-11 inline-block">TEL.</span> {tel} <br />
                <span className="w-11 inline-block">FAX.</span> {fax} <br />
                <span className="w-11 inline-block">E.</span> {email} <br />
              </p>
            </div>
          </div>
          <div className="hidden md:grid md:col-span-1 xl:grid-cols-2">
            <div className="grid grid-cols-1 gap-5 -mt-20 col-start-2 lg:gap-7">
              <Link to={"/about"}>
                <StyledLink>About</StyledLink>
              </Link>
              <Link to={`/${brands[0].slug}`}>
                <StyledLink>Products</StyledLink>
              </Link>
              <Link to={"/news"}>
                <StyledLink>News</StyledLink>
              </Link>
              <Link to={"/contact"}>
                <StyledLink>Contact</StyledLink>
              </Link>
            </div>
          </div>
        </div>
        <div className="text-xs text-center leading-normal container">
          <div className="md:text-left">
            ©PRO GYM DESIGNS CO., LTD All Rights Reserved.{" "}
            <div className="sm:hidden" />
            Site by{" "}
            <a
              className="underline"
              href="https://mounts-studio.com/"
              target="_blank"
            >
              Mounts Studio
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
